<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Applications</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Applications"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-sbpm-applications' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Applications
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table
            class="mb-5 table-bordered"
            v-for="group in applications_group"
            v-bind:key="group.id"
          >
            <tbody>
              <tr>
                <td></td>
                <td colspan="6">
                  Property: {{ group.property.address.short }}
                </td>
              </tr>
              <tr>
                <td colspan="7">Group: {{ group.lead.full_name }}</td>
              </tr>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>

                <th>Affordability</th>

                <th>
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                  >
                    Reject
                  </v-btn>
                </th>
              </tr>

              <tr
                v-for="application in group.applications"
                v-bind:key="application.id"
              >
                <td>{{ application.name }}</td>
                <td>{{ application.email }}</td>
                <td>{{ application.phone }}</td>
                <td>{{ application.status }}</td>
                <td>
                  <div v-if="application.affordability">
                    <v-icon color="green">mdi-check-circle </v-icon>
                  </div>
                  <div v-else>
                    <v-icon color="red">mdi-close-circle </v-icon>
                  </div>
                </td>

                <td></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Property</v-card-title>
        <v-card-text
          >Are you sure you want to restore this property?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
  },
  data() {
    return {
      gridData: this.Applications,
      buttonSwitchViewText: "Switch to List",
      isGridView: true,
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Applications",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        property: {},
      },
      tableHeaders: [
        { text: "Address", value: "address" },
        { text: "Status", value: "status" },
        { text: "Customer", value: "customer.full_name" },
        { text: "Type", value: "type" },
        { text: "Price", value: "price" },
        { text: "Viewing Done By?", value: "viewings_performed_by" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    applications_group() {
      let groups = this.$store.getters["sbpm/applicationsStore/archived"];

      if (this.searchTerm !== "") {
        groups = groups.filter((p) => {
          const short = p.property.address.short.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return short.includes(searchTerm);
        });
      }

      return groups;
    },
  },

  methods: {
    openRestore(property) {
      this.restoreDialog.property = property;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.property = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("melrose/ApplicationsStore/restoreProperty", {
          appId,
          propertyId: this.restoreDialog.property.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
